<template>
    <div class="addDelivery-content">
        <el-breadcrumb separator=">" class="genera-breadcrumb">
            <el-breadcrumb-item>网上交易管理</el-breadcrumb-item>
            <el-breadcrumb-item>发货地址管理</el-breadcrumb-item>
            <el-breadcrumb-item>新增发货地址</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="addDelivery-info">
            <div class="addDelivery-title">新增发货地址</div>
        </div>
        <el-form :model="addressForm" ref="addressForm" label-position="right" :rules="addressRules" label-width="112px" @close="resetForm()">
            <el-form-item label="所在地区" prop="where_address" class="where-address">
                <div style="display: flex;">
                    <el-select v-model="addressForm.province"  clearable placeholder="请选择省" style="width: 150px;margin-right: 10px;" @change="selectProvince">
                        <el-option
                                v-for="item in provinceList"
                                :key="item.area_id"
                                :label="item.area_name"
                                :value="item.area_name">
                        </el-option>
                    </el-select>
                    <el-select v-model="addressForm.city"  clearable placeholder="请选择市" style="width: 150px;margin-right: 10px;" @change="selectCity">
                        <el-option
                                v-for="item in cityList"
                                :key="item.area_id"
                                :label="item.area_name"
                                :value="item.area_name">
                        </el-option>
                    </el-select>
                    <el-select v-model="addressForm.area"  clearable placeholder="请选择区" style="width: 150px;margin-right: 10px;" @change="slelctArea">
                        <el-option
                                v-for="item in areaList"
                                :key="item.area_id"
                                :label="item.area_name"
                                :value="item.area_name">
                        </el-option>
                    </el-select>
                </div>
            </el-form-item>
            <div class="form-item">
                <el-form-item label="详细地址" prop="detailed_address">
                    <el-input v-model="addressForm.detailed_address"  autocomplete="off" style="width:340px"
                              placeholder="请输入详细地址" ></el-input>
                </el-form-item>
                <span class="prompt-title">注：英文数字不能超过100个，汉字不能超过50个</span>
            </div>
            <el-form-item label="邮编" prop="postcode" class="emil-num">
                <el-input v-model.number="addressForm.postcode" placeholder="请输入邮编" maxlength="6" clearable style="width: 340px"></el-input>
<!--                     <el-input-number v-model="addressForm.postcode"  maxlength="6" :controls="false"  placeholder="请输入邮编" style="width:340px"></el-input-number>-->
            </el-form-item>
            <div class="form-item">
                <el-form-item label="联系电话"  prop="phone">
                    <el-input v-model="addressForm.phone" placeholder="请输入联系电话" maxlength="13" clearable style="width:340px"></el-input>
                </el-form-item>
                <span class="prompt-title">注：支持手机号或固定电话</span>
            </div>
            <div class="form-item">
                <el-form-item label="发货人姓名"  prop="ship_name">
                    <el-input v-model="addressForm.ship_name"  placeholder="请输入发货人姓名" style="width:340px"></el-input>
                </el-form-item>
                <span class="prompt-title">注：不能超过5个字符</span>
            </div>
            <el-form-item label="是否默认"  prop="defualt">
                <el-checkbox v-model="addressForm.defualt"></el-checkbox>
            </el-form-item>
        </el-form>
        <div class="dialog-footer">
            <el-button @click="backBtn()">返回</el-button>
            <el-button style="margin-left: 30px" type="primary" @click="addressBtn('addressForm')">确定</el-button>
        </div>
    </div>
</template>

<script>
    import {transactionGetArea,transactionStuShipAddress,transactionStuShipAddressList,tranDistributionStuShipAddress,tranDistributionStuShipAddressList} from '@/utils/apis'
    export default {
        name: "StudentDeliveryAddress",
        data(){
            //手机号验证
            let checkPhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error("手机号不能为空"));
                } else {
                    if (value.length === 11) {
                        // 11位手机号码
                        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                        if (reg.test(value)) {
                            callback();
                        } else {
                            return callback(new Error("请输入正确的手机号"));
                        }
                    } else {
                        if (!/^0\d{2,3}-?\d{7,8}$/.test(value)) {
                            return callback(new Error("请输入正确的手机号"));
                        } else {
                            callback();
                        }
                    }
                }
            };
            // 验证地址
            let checkAddress = (rule, value, callback) => {
                let reg = "[^\u4e00-\u9fa5]";
                let zhStr = value.replaceAll(reg, "");
                let numStr = value.replace(/[^0-9]/gi, "");
                let enStr = value.replace(/[^a-z]+/gi, "");
                if ((zhStr.length-numStr.length) > 50 || (numStr.length + enStr.length) > 100) {
                    callback(new Error("英文数字不能超过100个，汉字不能超过50个"));
                } else {
                    callback();
                }
            };
            let checkPostcode = (rule, value, callback) => {
                if (value) {
                    const reg = /[1-9]\d{5}(?!\d)/
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('邮编格式不正确'));
                    }
                } else {
                    callback();
                }  
            };
            return{
                exam_module_id:Number(this.$route.query.exam_module_id)||'',
                addressForm:{
                    id:'',
                    where_address:'',
                    province:'',
                    city:'',
                    area:'',
                    detailed_address:'',
                    postcode:'',
                    phone:'',
                    ship_name:'',
                    defualt:'',
                },
                provinceList:[],
                cityList:[],//市
                areaList:[],//区
                addressRules: {
                    detailed_address: {required: true, validator: checkAddress, trigger:  ['blur', 'change']},
                    phone: {required: true,validator: checkPhone, trigger: ['blur', 'change']},
                    ship_name:[
                        {required: true, message: '请输入发货人姓名', trigger: 'blur'},
                        { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
                    ],
                    postcode: {validator: checkPostcode, trigger: ['blur']},
                },
            }
        },
        mounted() {
            this.getAreaList()
            if (this.$route.query.r_id){
                this.getTransactionStuShipAddressList()
            }
        },
        methods:{
            //获取地区列表
            async getAreaList() {
                let res = await transactionGetArea();
                this.provinceList = res.data;
                // transactionGetArea().then((res) => {
                //     this.provinceList = res.data;
                // }).catch((err)  => {
                //     console.log(err); 
                // })
            },
            //根据省选择市
            selectProvince(val) {
                if (val) {
                    this.provinceList.forEach(item => {
                        if (item.area_name === val) {
                            this.cityList = item.children_city;
                        }
                    })
                }
                this.addressForm.city = this.cityList[0].area_name
                this.selectCity(this.addressForm.city)
            },
            //根据市选择区
            selectCity(val){
                if (val) {
                    this.cityList.forEach(item => {
                        if (item.area_name === val) {
                            this.areaList = item.children_region;
                        }
                    })
                }
                this.addressForm.area = this.areaList[0].area_name
            },
            slelctArea(val) {
                if (val) {
                    this.addressForm.area = val;
                    this.getAreaList()
                }
            },
            // 获取详情
            async getTransactionStuShipAddressList(){
                let param = {
                    id: this.$route.query.r_id,
                }
                if (this.$route.query.op_id) {
                    param.op_id = this.$route.query.op_id;
                }
                if (this.$route.query.course_id) {
                    param.course_id = this.$route.query.course_id;
                }
                if (this.exam_module_id===40){
                    let res1 = await transactionGetArea();
                    this.provinceList = res1.data;
                    
                    let res = await transactionStuShipAddressList(param);
                    this.addressForm = res.data[0]
                    this.addressForm.defualt = res.data[0].defualt===1?true:false
                    let arr = res.data[0].where_address;
                    this.addressForm.province = arr[0];
                    this.addressForm.city = arr[1];
                    this.addressForm.area = arr[2];

                    this.provinceList.forEach(item => {
                        if (item.area_name === arr[0]) {
                            this.cityList = item.children_city;
                        }
                    });
                    this.cityList.forEach(item => {
                        if (item.area_name === arr[1]) {
                            this.areaList = item.children_region;
                        }
                    });
                    // transactionStuShipAddressList(param).then((res) => {
                    //     this.addressForm = res.data[0]
                    //     this.addressForm.defualt = res.data[0].defualt===1?true:false
                    //     let arr = res.data[0].where_address.splice(" ");
                    //     this.addressForm.province = arr[0];
                    //     this.addressForm.city = arr[1];
                    //     this.addressForm.area = arr[2];
                        
                    // }).catch((err) => {
                    //     console.log(err);
                    // })
                }else {
                    let res1 = await transactionGetArea();
                    this.provinceList = res1.data;
                    
                    let res = await tranDistributionStuShipAddressList(param);
                    this.addressForm = res.data[0]
                    this.addressForm.defualt = res.data[0].defualt===1?true:false
                    let arr = res.data[0].where_address;
                    this.addressForm.province = arr[0];
                    this.addressForm.city = arr[1];
                    this.addressForm.area = arr[2];

                    this.provinceList.forEach(item => {
                        if (item.area_name === arr[0]) {
                            this.cityList = item.children_city;
                        }
                    });
                    this.cityList.forEach(item => {
                        if (item.area_name === arr[1]) {
                            this.areaList = item.children_region;
                        }
                    });
                    // tranDistributionStuShipAddressList(param).then((res) => {
                    //     this.addressForm = res.data[0]
                    //     this.addressForm.defualt = res.data[0].defualt===1?true:false
                    //     let arr = res.data[0].where_address;
                    //     this.addressForm.province = arr[0];
                    //     this.addressForm.city = arr[1];
                    //     this.addressForm.area = arr[2];
                    // }).catch((err) => {
                    //     console.log(err);
                    // })
                }
            },
            //返回
            backBtn() {
                if (this.$route.query.op_id){
                    this.$router.push({
                        path: '/student/trainCenter/startTraining/deliveryaddress',
                        query:{
                            exam_module_id:this.exam_module_id,
                            op_id:this.$route.query.op_id,
                            course_id:this.$route.query.course_id,
                        }
                    })
                }else {
                    this.$router.push({
                        path: '/student/exam/onlineTradeManage/deliveryaddress',
                        query:{
                            exam_module_id:this.exam_module_id,
                        }
                    })
                }
            },
            //确定
            addressBtn(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let param ={
                            deliver_address:[]
                        }
                        let where_address = this.addressForm.province + " " + this.addressForm.city + " " + this.addressForm.area
                        let obj = {
                            where_address: where_address,
                            detailed_address: this.addressForm.detailed_address,
                            postcode: this.addressForm.postcode,
                            phone: this.addressForm.phone,
                            ship_name: this.addressForm.ship_name,
                            defualt: this.addressForm.defualt?1:0,
                        }
                        param.deliver_address.push(obj)
                        if (this.$route.query.r_id){
                           obj.id=this.$route.query.r_id
                        }
                        if (this.$route.query.op_id){
                            param.op_id=this.$route.query.op_id
                        }
                        if (this.$route.query.course_id){
                            param.course_id=this.$route.query.course_id
                        }
                        if(this.addressForm.province==='' || this.addressForm.city===''||this.addressForm.area===''){
                            this.$message({type:'error', message:'所在地区不能为空'})
                            return ;
                        }
                        if (this.exam_module_id===40){
                            transactionStuShipAddress(param).then((res) => {
                                this.$message.success(res.msg)
                                if (this.$route.query.op_id){
                                    this.$router.push({
                                        path: '/student/trainCenter/startTraining/deliveryaddress',
                                        query:{
                                            exam_module_id:this.$route.query.exam_module_id,
                                            op_id:this.$route.query.op_id,
                                            course_id:this.$route.query.course_id,
                                        }
                                    })
                                }else {
                                    this.$router.push({
                                        path: '/student/exam/onlineTradeManage/deliveryaddress',
                                        query:{
                                            exam_module_id:this.exam_module_id,
                                        }
                                    })
                                }
                            }).catch((err) => {
                                console.error('err', err);
                            });
                        }else if(this.exam_module_id===41){
                            tranDistributionStuShipAddress(param).then((res) => {
                                this.$message.success(res.msg)
                                if (this.$route.query.op_id){
                                    this.$router.push({
                                        path: '/student/trainCenter/startTraining/deliveryaddress',
                                        query:{
                                            exam_module_id:this.exam_module_id,
                                            op_id:this.$route.query.op_id,
                                            course_id:this.$route.query.course_id,
                                        }
                                    })
                                }else {
                                    this.$router.push({
                                        path: '/student/exam/onlineTradeManage/deliveryaddress',
                                        query:{
                                            exam_module_id:this.exam_module_id,
                                        }
                                    })
                                }
                            }).catch((err) => {
                                console.error('err', err);
                            });
                        }
                    } else {
                        return false;
                    }
                });
            },
            // 重置
            resetForm(){
                this.$refs.addressForm.resetFields();
                this.addressForm = this.$options.data().addressForm;
            }
        }
    }
</script>

<style scoped lang="scss">
    .addDelivery-content{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-left: 20px;
        .addDelivery-info {
            padding: 0 20px;
            /*height: calc(100vh - 220px);*/
            .addDelivery-title {
                font-size: 20px;
                padding-left: 15px;
                line-height: 1;
                margin: 20px 0 30px;
                display: flex;
                align-items: center;
            }
        }
        ::v-deep .el-form{
            height: 100%;
        }
        .dialog-footer {
            text-align: center;
            margin: 20px 0;
        }
        .form-item{
            display: flex;
            align-items: center;
            .prompt-title{
                color: #999;
                font-size: 16px;
                margin: -24px 10px 0;
            }
        }
        .where-address{
            position: relative;
            &:after{
                content: '*';
                color: #E84932;
                position: absolute;
                top: 12px;
                left: 35px;

            }
        }
    }
</style>